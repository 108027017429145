import { graphql, useStaticQuery } from 'gatsby';
import React, { FormEvent, useState } from 'react';
import { feedback } from 'react-feedbacker';
import { gql, useMutation } from 'urql';

import { useFeedbackMessages } from '@/bits';
import { FileDrop, useFileDropFile } from '@/bits/file-drop';
import { Card, CardBody, ErrorMessage, SubmitButton } from '@/components';
import { useTranslate } from '@/contexts';
import { Nullable } from '@/types';
import {
  BulkAdjustmentDepositMutation,
  BulkAdjustmentDepositMutationVariables,
} from './__generated__/BulkAdjustmentDepositMutation';
import { BulkAdjustmentsBlockStaticQuery } from './__generated__/BulkAdjustmentsBlockStaticQuery';
import { GenerateBulkDocumentUrl } from './__generated__/GenerateBulkDocumentUrl';

const BLOCK_STATIC_QUERY = graphql`
  query BulkAdjustmentsBlockStaticQuery {
    sanityBulkAdjustmentsBlock {
      title {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const generateBulkDocumentUrlMutation = gql`
  mutation GenerateBulkDocumentUrl {
    generateBulkDocumentUrl {
      url
      documentId
    }
  }
`;

const bulkAdjustmentDepositMutation = gql`
  mutation BulkAdjustmentDepositMutation($id: String!) {
    bulkAdjustmentDeposit(id: $id) {
      amountToProcess
    }
  }
`;

const BulkAdjustmentsBlock = () => {
  const { t } = useTranslate();
  const block = useStaticQuery<BulkAdjustmentsBlockStaticQuery>(
    BLOCK_STATIC_QUERY,
  ).sanityBulkAdjustmentsBlock;
  const { dispatch, file } = useFileDropFile();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const feedbackMessages = useFeedbackMessages();
  const [isUploading, setIsUploading] = useState(false);

  const [, generateBulkDocumentUrl] = useMutation<GenerateBulkDocumentUrl>(
    generateBulkDocumentUrlMutation,
  );

  const [, bulkAdjustmentDeposit] = useMutation<
    BulkAdjustmentDepositMutation,
    BulkAdjustmentDepositMutationVariables
  >(bulkAdjustmentDepositMutation);

  if (!block) {
    return null;
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!file) {
      return;
    }

    try {
      setIsUploading(true);

      const docUrlRes = await generateBulkDocumentUrl();

      const signedUrl = docUrlRes.data?.generateBulkDocumentUrl.url;
      const documentId = docUrlRes.data?.generateBulkDocumentUrl.documentId;

      if (!signedUrl || !documentId) {
        setIsUploading(false);
        return;
      }

      const params = {
        body: file,
        method: 'PUT',
        headers: { 'Content-Type': file.type as string },
      };

      await fetch(signedUrl, params);
      await bulkAdjustmentDeposit({ id: documentId });

      feedback.success(t(feedbackMessages.success));

      dispatch({
        type: 'RESET',
      });
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Card size="lg" title={t(block.title)}>
      <CardBody>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-3 p-3">
            <FileDrop
              dispatch={dispatch}
              file={file}
              accept="text/csv"
              setErrorMessage={setErrorMessage}
            />
            <SubmitButton
              value={t(block.submit)}
              disabled={!!errorMessage || !file || isUploading}
            />
            <ErrorMessage message={errorMessage} />
          </div>
        </form>
      </CardBody>
    </Card>
  );
};

export default BulkAdjustmentsBlock;
